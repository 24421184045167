




























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class ImportChoice extends Vue {
  @Prop() selected!: boolean;
  @Prop() title!: boolean;
  @Prop() description!: boolean;
  @Prop() recommended!: boolean;

  get isSelected() {
    return this.selected;
  }
}
