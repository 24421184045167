































































import { Component, Vue } from 'vue-property-decorator';
import ImportChoice from '@/pages/import/components/ImportChoice.vue';

@Component({
  components: {
    ImportChoice,
  },
})
export default class ImportChoicesPage extends Vue {}
